// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-howtoapply-js": () => import("./../../../src/pages/howtoapply.js" /* webpackChunkName: "component---src-pages-howtoapply-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-licensingcenters-js": () => import("./../../../src/pages/licensingcenters.js" /* webpackChunkName: "component---src-pages-licensingcenters-js" */),
  "component---src-pages-tracklicence-js": () => import("./../../../src/pages/tracklicence.js" /* webpackChunkName: "component---src-pages-tracklicence-js" */),
  "component---src-pages-verify-js": () => import("./../../../src/pages/verify.js" /* webpackChunkName: "component---src-pages-verify-js" */)
}

